exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-case-study-articles-js": () => import("./../../../src/pages/case-study/articles.js" /* webpackChunkName: "component---src-pages-case-study-articles-js" */),
  "component---src-pages-case-study-index-js": () => import("./../../../src/pages/case-study/index.js" /* webpackChunkName: "component---src-pages-case-study-index-js" */),
  "component---src-pages-case-study-kanno-sharoshi-js": () => import("./../../../src/pages/case-study/kanno-sharoshi.js" /* webpackChunkName: "component---src-pages-case-study-kanno-sharoshi-js" */),
  "component---src-pages-case-study-yasumuro-setubi-js": () => import("./../../../src/pages/case-study/yasumuro-setubi.js" /* webpackChunkName: "component---src-pages-case-study-yasumuro-setubi-js" */),
  "component---src-pages-column-editors-note-js": () => import("./../../../src/pages/column/editors-note.js" /* webpackChunkName: "component---src-pages-column-editors-note-js" */),
  "component---src-pages-column-index-js": () => import("./../../../src/pages/column/index.js" /* webpackChunkName: "component---src-pages-column-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dx-council-articles-js": () => import("./../../../src/pages/dx-council/articles.js" /* webpackChunkName: "component---src-pages-dx-council-articles-js" */),
  "component---src-pages-dx-council-first-real-meeting-js": () => import("./../../../src/pages/dx-council/first-real-meeting.js" /* webpackChunkName: "component---src-pages-dx-council-first-real-meeting-js" */),
  "component---src-pages-dx-council-index-js": () => import("./../../../src/pages/dx-council/index.js" /* webpackChunkName: "component---src-pages-dx-council-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-column-js": () => import("./../../../src/templates/column.js" /* webpackChunkName: "component---src-templates-column-js" */),
  "component---src-templates-report-js": () => import("./../../../src/templates/report.js" /* webpackChunkName: "component---src-templates-report-js" */)
}

